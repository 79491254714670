import Ajax from './Ajax';

/**
* Utility helper for Account functions
*/

class Creator {
  constructor() {
    this.creatorData = null;
    this.assetData = null;
    this.dropData = null;
    this.creatorId = process.env.CREATOR_ID;
    this.assetId = process.env.ASSET_ID;
    this.dropId = process.env.DROP_ID;
    this.apiUrl = process.env.SERVER_URL;
  }

  createRisk = (payloadData, token) => {
    const payload = {
      assetPrice: payloadData.purchase,
      gasFee: payloadData.gas,
      tax: payloadData.tax,
      dropId: this.dropId,
    };
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/create', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  createDecision = (payloadData, token) => {
    const payload = {
      assetPrice: payloadData.price,
      gasFee: payloadData.gasFee,
      tax: payloadData.tax,
      dropId: this.dropId,
    };
    const that = this;
    return Ajax.post(that.apiUrl + 'risk/decision', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  confirmPurchase = (payload, token) => {
    const that = this;
    return Ajax.post(that.apiUrl + 'payment/pay', payload, { Authorization: token ? 'Bearer ' + token : '' })
      .then((data) => data)
      .catch((error) => {
        throw error;
      });
  }

  getDrop(dropId) {
    const that = this;
    let assetData = null;
    let dropData = null;
    if (this.creatorData !== null) {
      return this.creatorData;
    }
    return Ajax.get(that.apiUrl + 'drop/' + dropId)
      .then((data) => {
        data.assets.forEach((asset) => {
          if (asset.assetId === that.assetId) {
            assetData = asset;
          }
        });
        assetData.drops.forEach((drop) => {
          if (drop.dropId === that.dropId) {
            dropData = drop;
          }
        });
        const creatorData = {
          creator: data,
          asset: assetData,
          drop: dropData,
        };
        that.creatorData = creatorData;
        return creatorData;
      })
      .catch((error) => {
        // that.clear();
        throw error;
      });
  }
}

export default new Creator();
