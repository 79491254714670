import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown';
import moment from 'moment-mini';
import { Link } from 'react-router-dom';

class HomeHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countdownData: null,
    };
  }

  setHeroHeight = () => {
    const hero = document.getElementById('landingHero');
    if (hero) {
      hero.style.height = window.innerHeight + 'px';
    }
  }

  parseCountdown = (date) => {
    this.setState({
      countdownData: moment(date).unix() * 1000,
    });
  }

  componentDidMount() {
    const { data } = this.props;
    const that = this;
    this.setHeroHeight();
    window.addEventListener('resize', that.setHeroHeight);
    that.parseCountdown(data.drop.dateOpen);
  }

  learnMore = (e) => {
    const { onLearnMore } = this.props;
    e.preventDefault();
    onLearnMore();
  }

  goToFaq = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  render() {
    const { data, type } = this.props;
    const { countdownData } = this.state;
    const renderer = ({
      days, hours, minutes, seconds, completed,
    }) => {
      if (completed) {
        window.location.href = '/';
      }
      // Render a countdown
      return (
        <div>
          <div className="countdown-item">
            {days}
            {' '}
            <span>days</span>
          </div>
          <div className="countdown-item">
            {hours}
            {' '}
            <span>hours</span>
          </div>
          <div className="countdown-item">
            {minutes}
            {' '}
            <span>mins</span>
          </div>
          <div className="countdown-item">
            {seconds}
            {' '}
            <span>secs</span>
          </div>
        </div>
      );
    };
    return (
      <div id="landingHero" className="landing-hero">
        <div className="landing-hero__inner">
          <div className="landing-hero__text -center-vert">
            {type === 'countdown'
              && <div className="hero-title__logo"><img src="https://ethosassets.z13.web.core.windows.net/drops/lovejules/nav-logo-lovejules.svg" alt="Love Jules Leather" /></div>}
            <h1 className="hero-title">FAMILY. FIRST. FOREVER.</h1>
            <p className="hero-description">
              {'12 years later... we find ourselves at a crossroads. To mark and celebrate this next chapter, we\'re releasing our very first limited run '}
              {' '}
              <a
                href="#"
                onClick={(e) => this.goToFaq(e)}>
                NFT
              </a>
              .
              The 50 folks who choose to support us through this foundational project will forever have first dibs on each and every limited run release, as well as a lifetime discount on all future&nbsp;purchases.
              <br />
              Family.&nbsp;First.&nbsp;Forever.
            </p>
            <p className="hero-description">NFTs will drop at Noon PST on Wednesday, June 29,&nbsp;2022. </p>
            {type === 'countdown'
            && (
            <div className="hero-countdown__inline">
              {countdownData
              && (
              <Countdown
              date={countdownData}
              renderer={renderer} />
              )}
            </div>
            )}
            {type === 'countdown'
              ? <a href="#" className="button-hero" onClick={(e) => this.learnMore(e)}>Learn More</a>
              : <Link className="button-hero" to={'/drop/' + data.drop.dropId}>View Details</Link>}
          </div>
        </div>
      </div>
    );
  }
}

HomeHero.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  type: PropTypes.string,
  onLearnMore: PropTypes.func,
  onFaqButton: PropTypes.func,
};

export default HomeHero;
