import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Register from '../register/index.jsx';
import SimpleModal from '../modal/modal';

class HomeCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  componentDidMount() {}

  readMore = (e) => {
    const { onFaqButton } = this.props;
    e.preventDefault();
    onFaqButton();
  }

  getNotified = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  }

  render() {
    const { data } = this.props;
    const { showModal } = this.state;
    return (
      <div id="moreInfoElement" className="featured-drop__outer">
        <SimpleModal showLogo theme="branded" isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
          <Register type="modal" />
        </SimpleModal>
        <div className="landing-featured-drop">
          <div className="featured-drop__row">
            <div className="featured-drop__col-image">
              <img src={data.asset.mediaThumbnailUrl} alt="ethos" />
            </div>
            <div className="featured-drop__col-text">
              <div className="featured-drop__vertical-center">
                <div
                  className="featured-drop__title"
                  dangerouslySetInnerHTML={{ __html: data.asset.name }}>
                </div>
                <div className="featured-drop__description">
                  <div dangerouslySetInnerHTML={{ __html: data.asset.description }}>
                  </div>
                  <p>Pre-register now and gain access to the drop 24 hours before we go public.</p>
                </div>

                <div className="featured-drop__cta">
                  <a
                    className="button button-disabled">
                    Pre-Registration Closed
                  </a>
                  {/* <a
                    href="#"
                    className="button"
                    onClick={(e) => this.getNotified(e)}>
                    Pre-Register
                  </a> */}
                  <a
                    href="#"
                    className="button-text"
                    onClick={(e) => this.readMore(e)}>
                    Read the FAQ
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeCountdown.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  onFaqButton: PropTypes.func,
};

export default HomeCountdown;
