import React, { Component } from 'react';

class AccountWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="account-box -account-section">
          <h2 className="account-heading__h2">
            Wallet Settings
          </h2>
          <div className="account-subheading">
            Change your digital wallet here
          </div>
          Coming soon!
          <p>We are currently working on functionality that will allow you to freely transfer your NFT purchases to your own wallet.</p>
        </div>
      </div>
    );
  }
}

export default AccountWallet;
