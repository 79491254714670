import React, { Component } from 'react';
import HomeHero from '../components/hero/homeHero';
import HomeDrop from '../components/featuredDrop/homeDrop';
import FAQ from '../components/about/faq';
import CreatorManager from '../managers/Creator';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      dropData: null,
    };
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    if (dropData) {
      this.setState({
        dropData,
      });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.getDropData();
  }

  scrollToSection = (element) => {
    const scrollToElement = document.getElementById(element);
    scrollToElement.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    const { dropData } = this.state;
    return (
      <div>
        {dropData
        && (
        <div>
          <HomeHero
            data={dropData}
            type="landing"
            onFaqButton={() => this.scrollToSection('homeFaq')} />
          <HomeDrop data={dropData} />
          <FAQ />
        </div>
        )}
      </div>
    );
  }
}

export default Home;
