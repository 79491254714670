import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PubSub from 'pubsub-js';
import Throbber from '../throbber';
import Account from '../../managers/Account';
import SimpleModal from '../modal/modal';
import LoginForm from './loginForm';

const emailPattern = /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
const passwordRequirements = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

class AccountRegister extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registerFirstname: '',
      registerLastname: '',
      registerEmail: '',
      registerPassword: '',
      registerConfirmPassword: '',
      registerDisplayname: '',
      registerPhoneNumber: '',
      loginVerification: '',
      userId: '',
      isLoading: false,
      isSuccess: false,
      isMfa: false,
      agreeTerms: false,
      showModal: false,
      creatorId: process.env.CREATOR_ID,
    };
  }

  validateForm = () => {
    const { registerFirstname } = this.state;
    const { registerLastname } = this.state;
    const { registerEmail } = this.state;
    const { registerPassword } = this.state;
    const { registerConfirmPassword } = this.state;
    const { registerDisplayname } = this.state;
    const { registerPhoneNumber } = this.state;
    const { agreeTerms } = this.state;
    if (registerFirstname === ''
        || registerLastname === ''
        || registerEmail === ''
        || registerPassword === ''
        || registerConfirmPassword === ''
        || registerDisplayname === ''
        || registerPhoneNumber === '') {
      toast.error('Please fill out all fields.');
      return false;
    }
    if (!emailPattern.test(registerEmail)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    if (registerPassword !== registerConfirmPassword) {
      toast.error('Passwords don\'t match.');
      return false;
    }
    if (!passwordRequirements.test(registerPassword)) {
      toast.error('Passwords must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters');
      return false;
    }
    if (!agreeTerms) {
      toast.error('You must agree to our terms of service.');
      return false;
    }
    return true;
  }

  handleInputChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  onEnterKey = (e) => {
    const { isMfa } = this.state;
    if (e.keyCode === 13) {
      if (isMfa) {
        this.mfaLogIn(e);
      } else {
        this.validateForm(e);
      }
    }
  }

  handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    this.setState({
      agreeTerms: isChecked,
    });
  }

  register = async (e) => {
    const {
      registerFirstname, registerLastname, registerEmail, registerPassword, registerDisplayname, registerPhoneNumber, creatorId,
    } = this.state;
    let payload = {};
    e.preventDefault();
    if (this.validateForm()) {
      this.setState({
        isLoading: true,
      });
      // package up the payload and send it to the server
      payload = {
        email: registerEmail,
        firstname: registerFirstname,
        lastname: registerLastname,
        username: registerDisplayname,
        password: registerPassword,
        telephone: registerPhoneNumber,
        site: window.location.protocol + '//' + window.location.host,
        creatorId,
      };
      const account = await Account.create(process.env.SERVER_URL + 'account/register', payload);
      
      if (account && account.userId) {
        this.setState({
          isLoading: false,
          isMfa: true,
          userId: account.userId,
        });
      } else if (account && account.token) {
        this.setState({
          isLoading: false,
        });
        // go get user info
        const userInfo = await Account.get(account.token);
        if (userInfo) {
          // this.setState({
          //   isLoading: false,
          //   isSuccess: true,
          // });
          // set login cookie
          Account.setLoginState(account.token);
          // fire pubsub
          PubSub.publish('account_updated');
          window.location.href = '/drop/' + process.env.DROP_ID + '#login=success';
        }
      } else {
        this.setState({
          isLoading: false,
        });
        toast.error(account.message || 'Oops! Something went wrong. Please try again later');
      }
    }
  }

  componentDidMount() {
    document.body.classList.add('-account-background');
  }

  componentWillUnmount() {
    document.body.classList.remove('-account-background');
  }

  loginSuccess = async () => {
    this.setState({
      showModal: false,
      isLoggedIn: true,
    });
    toast.success('Log in success.');
    PubSub.publish('account_updated');
    if (window.location.pathname.includes('register')) {
      window.location.pathname = '/';
    }
  }

  loginFailed = (error) => {
    toast.error(error || 'Oops! Something went wrong. Try again later.');
  }

  openLoginModal = (e) => {
    e.preventDefault();
    this.setState({
      showModal: true,
    });
  }

  mfaLogIn = async (e) => {
    const { userId, loginVerification } = this.state;
    e.preventDefault();
    this.setState({
      isLoading: true,
    });
    const payload = {
      userId,
      token: loginVerification,
    };
    const login = await Account.mfaLogin(payload);
    if (login && login.token) {
      await Account.get(login.token);
      Account.setLoginState(login.token);
      gtag('event', 'lovejules_registration', { // eslint-disable-line
        event_category: 'account_registration_success',
        event_label: 'User registered for the lovejules drop',
      });
      window.location.href = '/drop/' + process.env.DROP_ID + '#register=success';
    } else {
      this.setState({
        isLoading: false,
      });
      toast.error(login.message || 'Oops! Something went wrong. Please try again later.');
    }
  }

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  }

  render() {
    const {
      loginVerification, registerFirstname, registerLastname, registerEmail, registerPassword, registerConfirmPassword, registerDisplayname, registerPhoneNumber, isLoading, isMfa, showModal,
    } = this.state;
    return (
      <div className="account-container__outer">
        <div className="account-container__inner">
          <SimpleModal isOpen={showModal} onBeforeClose={() => this.setState({ showModal: false })}>
            <LoginForm
              onSuccess={() => this.loginSuccess()}
              onFail={(error) => this.loginFailed(error)}
              onRegisterButton={() => this.closeModal()} />
          </SimpleModal>
          <div className="account-box -center">
            {isLoading
              && <Throbber throbberText="Creating your account! Hang tight..." />}
            <div className="account-heading">
              Register
            </div>
            {isMfa
              ? (
                <div className="form-success">
                  Thanks for signing up.
                  <br />
                  We have sent a verification code to your phone. Please enter this code below to access your account.
                  <br />
                  <br />
                  <div className="form-section">
                    <label className="label-block" htmlFor="loginVerification">Verification Code</label>
                    <input
                      className="input-verification"
                      id="loginVerification"
                      type="text"
                      value={loginVerification}
                      maxLength="6"
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                  </div>
                </div>
              )
              : (
                <div>
                  <div className="account-subheading">
                    Complete this form to create your ethos account.
                  </div>
                  <div className="form-container">
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerFirstname">First name</label>
                      <input
                      className="input-block"
                      id="registerFirstname"
                      type="text"
                      value={registerFirstname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerLastname">Last name</label>
                      <input
                      className="input-block"
                      id="registerLastname"
                      type="text"
                      value={registerLastname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerEmail">Email</label>
                      <input
                      className="input-block"
                      id="registerEmail"
                      autoComplete="new-email"
                      type="email"
                      value={registerEmail}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerPassword">Password</label>
                      <input
                      className="input-block"
                      id="registerPassword"
                      autoComplete="new-password"
                      type="password"
                      value={registerPassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                      <div className="form-requirements">Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters</div>
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerConfirmPassword">Confirm password</label>
                      <input
                      className="input-block"
                      id="registerConfirmPassword"
                      type="password"
                      value={registerConfirmPassword}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerDisplayname">Display name</label>
                      <input
                      className="input-block"
                      id="registerDisplayname"
                      type="text"
                      value={registerDisplayname}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                    </div>
                    <div className="form-section">
                      <label className="label-block" htmlFor="registerPhoneNumber">Phone number</label>
                      <input
                      className="input-block"
                      id="registerPhoneNumber"
                      type="text"
                      value={registerPhoneNumber}
                      onChange={(e) => this.handleInputChange(e)}
                      onKeyUp={(e) => this.onEnterKey(e)} />
                      <div className="form-requirements">A working mobile phone number is required for log in</div>
                    </div>
                    <div className="form-section">
                      <input
                      id="registerAcceptTerms"
                      type="checkbox"
                      onChange={(e) => this.handleCheckboxChange(e)} />
                      <label className="label-inline" htmlFor="registerAcceptTerms">
                        I agree to the
                        {' '}
                        <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                        .
                      </label>
                    </div>
                  </div>
                </div>
              )}
            {isMfa
              ? (
                <div className="form-cta">
                  <a href="#" className="button" onClick={(e) => this.mfaLogIn(e)}>Log In</a>
                  {/* <a href="/">Go back</a>
                {' '}
                |
                {' '}
                <a href="/account/details">View your account settings</a> */}
                </div>
              )
              : (
                <div className="form-cta">
                  <button
                  type="submit"
                  onClick={(e) => this.register(e)}>
                    Register
                  </button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default AccountRegister;
