import React, { Component } from 'react';
import { toast } from 'react-toastify';
import FeaturedDrop from '../components/featuredDrop/featuredDrop';
import SlimHero from '../components/hero/slimHero';
import CreatorManager from '../managers/Creator';
import FAQ from '../components/about/faq';

class Drop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      creatorId: process.env.CREATOR_ID,
      dropId: process.env.DROP_ID,
      dropData: null,
    };
  }

  getDropData = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    if (dropData) {
      this.setState({
        dropData,
      });
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    if (window.location.hash.includes('register=success')) {
      toast.success('Successfully created your account.');
      window.location.hash = '';
    }
    if (window.location.hash.includes('login=success')) {
      toast.success('Log in success.');
      window.location.hash = '';
    }
    await this.getDropData();
  }

  render() {
    const { dropData } = this.state;
    return (
      <div>
        {dropData
        && (
        <div>
          <SlimHero />
          <FeaturedDrop data={dropData} />
          <FAQ />
        </div>
        )}
      </div>
    );
  }
}

export default Drop;
