import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class HomeDrop extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { data } = this.props;
    return (
      <div className="featured-drop__outer">
        <div className="landing-featured-drop">
          <div className="featured-drop__row">
            <div className="featured-drop__col-image">
              <img src={data.asset.mediaThumbnailUrl} alt="ethos" />
            </div>
            <div className="featured-drop__col-text">
              <div className="featured-drop__vertical-center">
                <div
                  className="featured-drop__title"
                  dangerouslySetInnerHTML={{ __html: data.asset.name }}>
                </div>
                <div
                  className="featured-drop__description"
                  dangerouslySetInnerHTML={{ __html: data.asset.description }}>
                </div>
                <div className="featured-drop__description -padding-bottom">No crypto required. NFTs will be simple to purchase with any major credit card.</div>

                <div className="featured-drop__cta">
                  <Link className="button" to={'/drop/' + data.drop.dropId}>View Details</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

HomeDrop.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
};

export default HomeDrop;
