import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import Throbber from '../throbber';
import AccountManager from '../../managers/Account';
import CreatorManager from '../../managers/Creator';
import Ajax from '../../managers/Ajax';

class ConfirmPurchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      loadingText: 'Calculating taxes. Stand by...',
      agreeTerms: false,
      purchasePrice: null,
      prices: {},
      lastFour: '####',
    };
  }

  prettifyPrices = (purchaseObj) => {
    const subtotal = purchaseObj.purchase + purchaseObj.gas;
    const { tax } = purchaseObj;
    const total = subtotal + tax;

    // const prices = {
    //   purchase: (purchaseObj.purchase / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    //   gas: (purchaseObj.gas / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    //   subTotal: (subtotal / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    //   tax: (tax / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    //   taxRate: purchaseObj.taxRate || 0,
    //   total: (total / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
    //   rawTotal: total,
    // };

    const prices = {
      price: purchaseObj.purchase,
      gasFee: purchaseObj.gas,
      subTotal: subtotal,
      tax,
      taxRate: purchaseObj.taxRate || 0,
      total,
      rawTotal: total,
    };
    
    this.setState({
      prices,
    });

    // console.log(subtotal.toLocaleString('en-US', { style: 'currency', currency: 'USD' }));
    // calculate subtotal (purchase + gas)
    // calculate tax
    // calculate total
    // prettify purchase, gas, subtotal, tax, and total
  }

  calculateTaxes = async (amountObj) => {
    const { data } = this.props;
    const updatedAmountObj = amountObj;
    const taxes = await Ajax.get(process.env.SERVER_URL + 'tax?assetAmount=' + amountObj.purchase + '&gasAmount=' + amountObj.gas + '&dropId=' + data.drop.dropId, AccountManager.getToken());
    if (taxes && taxes.taxAmount) {
      updatedAmountObj.tax = taxes.taxAmount;
      updatedAmountObj.taxRate = taxes.taxRate;
    } else {
      updatedAmountObj.tax = 0;
      updatedAmountObj.taxRate = 0;
    }
    this.setState({
      isLoading: false,
      loadingText: 'Confirming your purchase. One sec...',
    });
    this.prettifyPrices(updatedAmountObj);
    await CreatorManager.createRisk(updatedAmountObj, AccountManager.getToken());
  }

  getCardDetails = async () => {
    let cardDetails;
    const hasCardOnFile = await AccountManager.getPaymentMethod(AccountManager.getToken());
    if (hasCardOnFile && hasCardOnFile.success) {
      cardDetails = hasCardOnFile.card;
      this.setState({
        lastFour: cardDetails.last4 || '####',
      });
    }
  }

  async componentDidMount() {
    const { data } = this.props;
    const updatedPricing = {
      purchase: this.convertToCents(data.drop.buyItNow),
      gas: 100,
      subTotal: 0,
      tax: 0,
      total: 0,
    };
    this.getCardDetails();
    await this.calculateTaxes(updatedPricing);
  }

  convertToCents = (amount) => amount * 100

  confirmPurchase = async () => {
    const { onPurchaseAttempt } = this.props;
    const { agreeTerms, prices } = this.state;
    if (!agreeTerms) {
      toast.error('Please agree to the terms and conditions.');
    } else {
      this.setState({
        isLoading: true,
      });
      await CreatorManager.createDecision(prices, AccountManager.getToken());
      onPurchaseAttempt(prices);
    }
  }

  componentDidUpdate(prevProps) {
    const { purchaseResult } = this.props;
    if (prevProps.purchaseResult !== purchaseResult) {
      console.log(purchaseResult);
    }
  }

  handleCheckboxChange = (e) => {
    let isChecked = false;
    if (e.target.checked) {
      isChecked = true;
    } else {
      isChecked = false;
    }
    this.setState({
      agreeTerms: isChecked,
    });
  }

  prettyPrice = (cents) => (cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })

  render() {
    const {
      lastFour, isLoading, prices, loadingText,
    } = this.state;
    return (
      <div>
        {isLoading
            && <Throbber throbberText={loadingText} />}
        <div className="account-heading">
          Confirm Your Purchase
        </div>
        <div className="confirm-purchase__container">
          {/* <div className="account-subheading">
            Confirm the details below details.
          </div> */}
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Purchase Price
            </div>
            <div className="confirm-purchase__item">
              {this.prettyPrice(prices.price)}
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Gas Fee
            </div>
            <div className="confirm-purchase__item -underline">
              {this.prettyPrice(prices.gasFee)}
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Sub Total
            </div>
            <div className="confirm-purchase__item">
              {this.prettyPrice(prices.subTotal)}
            </div>
          </div>
          {/* <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Tax Rate
            </div>
            <div className="confirm-purchase__item">
              {prices.taxRate}
              %
            </div>
          </div> */}
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Estimated Sales Tax
            </div>
            <div className="confirm-purchase__item -underline">
              {this.prettyPrice(prices.tax)}
            </div>
          </div>
          <div className="confirm-purchase__row">
            <div className="confirm-purchase__item">
              Total
              {' '}
              <span className="confirm-purchase__note">
                (will be charged to card ending with
                {' '}
                {lastFour}
                )
              </span>
            </div>
            <div className="confirm-purchase__item">
              {this.prettyPrice(prices.total)}
            </div>
          </div>

          <div className="confirm-purchase__legal">
            {/* <ul>
              <li>The NFT carries a limited licence referencing the artwork.</li>
              <li>Your purchase is conditional and will only become final upon a) our receipt in full of your payment b) validation of your identity which may require your submission of official documents c) completion of a hold period which may be as long as three months.</li>
              <li>All of your payments are non-refundable.</li>
            </ul> */}
            <div className="confirm-purchase__terms">
              <input
                id="registerAcceptTerms"
                type="checkbox"
                onChange={(e) => this.handleCheckboxChange(e)} />
              <label className="label-inline -inverse" htmlFor="registerAcceptTerms">
                I agree to the Terms &amp; Conditions
                {' '}
                <a href="https://www.ethosnft.com/terms" target="_blank" rel="noreferrer">Terms &amp; Conditions</a>
                .
              </label>
            </div>
          </div>
        </div>
        <div className="form-cta">
          <button
              type="submit"
              onClick={(e) => this.confirmPurchase(e)}>
            Confirm Purchase
          </button>
        </div>
      </div>
    );
  }
}

ConfirmPurchase.propTypes = {
  data: PropTypes.objectOf(PropTypes.object),
  onPurchaseAttempt: PropTypes.func,
  purchaseResult: PropTypes.string,
};

export default ConfirmPurchase;
