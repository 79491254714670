import React, { Component } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import TopNav from 'components/navigation/top-nav';
import Footer from 'components/navigation/footer';
import FullPageLoader from 'components/throbber/loader';
import AccountManager from 'managers/Account';
import CreatorManager from 'managers/Creator';

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropId: process.env.DROP_ID,
      isLoading: true,
      dropDetails: null,
    };
  }

  getAccountDetails = async () => {
    await AccountManager.get(AccountManager.getToken());
  }

  getDropDetails = async () => {
    const { dropId } = this.state;
    const dropData = await CreatorManager.getDrop(dropId);
    this.setState({
      isLoading: false,
      dropDetails: dropData,
    });
  }

  componentDidMount = async () => {
    // check if the user is logged in. If they are, grab the account details
    if (AccountManager.isLoggedIn()) {
      await this.getAccountDetails();
    }
    await this.getDropDetails();
  }

  render() {
    const { isLoading, dropDetails } = this.state;
    return (
      <div>
        {isLoading
          ? (
            <div>
              <FullPageLoader />
            </div>
          )
          : (
            <div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                pauseOnHover
                theme="dark" />
              <TopNav dropDetails={dropDetails} />
              <Outlet />
              <Footer />
            </div>
          )}
      </div>
    );
  }
}

export default Container;
